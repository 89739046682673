// Libs.
import React from 'react';
// Deps.
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import FlippContainer from '../components/flipp/flipp-container';

const FlyerPage = () => {
  return (
    <Layout>
      <SEO title="Flyer" />
      <FlippContainer />
    </Layout>
  );
};

export default FlyerPage;
