/* eslint-disable */
import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { AppContext } from '../layout/AppLayout';

import styles from './flipp-container.module.scss';

const FlippContainer = () => {
    const isApp = useContext(AppContext);

    return (
    <>
        <div id="flipp-container" className={isApp ? styles.flippContainerApp: ''}></div>
        <Helmet>
            <script type="text/javascript">
                {`
                var flipInitilized = true; // to restrict flipp init twice
                function initFlipp() {
                    console.log("Flipp initializedd", flipInitilized);
                    flipInitilized = false;
                    console.log("Flipp initializedd", flipInitilized);
                    new Flipp.Storefront({
                        accessToken: "5b4ad9bb0148449f25dbb0b76b976c1b",
                        merchantId: "1978",
                        merchantNameIdentifier:"longos",
                        locale: "en-CA"
                    }).renderStorefront(document.querySelector("#flipp-container"));
            }`}
            </script>
            <script type="module" src="https://aq.flippenterprise.net/1978/iframe.js" onload="initFlipp()" id="flipp-storefront-script">
            </script>
        </Helmet>
    </>
    );
}

export default FlippContainer;